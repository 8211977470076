interface ICacheStrategy {
	get<T>(key: string): T | undefined;
	set<T>(key: string, val: T): void;
	has(key: string): boolean;
	reset(): void;
}
class BaseCacheStrategy implements ICacheStrategy {
	get<T>(key: string) {
		return (window as any).$cache?.[key] as T;
	}
	set<T>(key: string, val: T) {
		if ('$cache' in (window as any) === false) {
			(window as any).$cache = {};
		}

		(window as any).$cache[key] = val;
	}
	has(key: string) {
		return '$cache' in (window as any) && key in (window as any).$cache;
	}
	reset(): void {
		(window as any).$cache = {};
	}
}

class CacheServiceController {
	private strategy: ICacheStrategy;

	constructor(_strategy: ICacheStrategy) {
		this.strategy = _strategy;
	}

	get<T>(key: string, defaultValue?: T): T | undefined {
		let value = this.strategy.get<T>(key);

		// Use default value
		if (!value && defaultValue) {
			value = defaultValue;
			this.strategy.set<T>(key, value);
		}

		return value;
	}
	set<T>(key: string, val: T): void {
		this.strategy.set<T>(key, val);
	}
	has(key: string): boolean {
		return this.strategy.has(key);
	}
	reset(): void {
		this.strategy.reset();
	}
}

export const CacheService = new CacheServiceController(new BaseCacheStrategy());
