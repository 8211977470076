import { APIService } from './base/APIService';

export type TopResult = {
	result: boolean;
	daily_top: TopUserItem[];
	three_days_top: TopUserItem[];
	ten_days_top: TopUserItem[];
};

export type TopUserItem = {
	user_id: string;
	user_nickname: string;
	user_avatar: string;
	likes_count: number;
};

export type Stats = {
	result: boolean;
	total_users: number;
	total_works: number;
	total_likes: number;
	total_conversions: number;
	total_uptime_days: number;
};

class TopServiceController {
	public async getStats(): Promise<Stats> {
		return await APIService.fetchCached(`/reports/statistics`, 'GET');
	}

	public async getTop(): Promise<TopResult> {
		return await APIService.fetchCached(`/reports/users_top`, 'GET');
	}
}

export const TopService = new TopServiceController();
