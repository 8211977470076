<script lang="ts" setup></script>
<template>
	<div class="back-link">
		<svg
			class="back-link__icon"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.8103 5.41402C16.134 5.86155 16.0335 6.4867 15.586 6.81032C13.518 8.30574 11.6602 10.0503 10.0574 12C11.6602 13.9497 13.518 15.6942 15.586 17.1897C16.0335 17.5133 16.134 18.1384 15.8103 18.586C15.4867 19.0335 14.8616 19.134 14.414 18.8103C12.163 17.1825 10.1434 15.2764 8.40697 13.141C7.86434 12.4737 7.86434 11.5263 8.40697 10.859C10.1434 8.72355 12.163 6.81745 14.414 5.18967C14.8616 4.86604 15.4867 4.96649 15.8103 5.41402Z"
				fill="white"
			/>
		</svg>
		<p v-if="$slots.default" class="back-link__text">
			<slot />
		</p>
	</div>
</template>
<style lang="scss" scoped>
.back-link {
	display: flex;
	align-items: center;

	margin-bottom: 24px;

	cursor: pointer;

	transition: var(--transition) opacity;

	&__text {
		margin-left: 12px;

		font-size: 18px;
		line-height: 24px;

		color: var(--color-text);
	}

	&:hover {
		opacity: 0.5;
	}
}
</style>
