import { APIService } from './base/APIService';

export type MessagesCount = {
	user_id: string;
	message_count: number;
};

export type Messages = MessagesCount & {
	messages: '' | Message[];
	result: string;
};

export type Message = {
	guid: string;
	message: string;

	image_url: string;

	sender_id: string;
	sender_name: string;
};

class MessagesServiceController {
	public async getMessagesCount(): Promise<MessagesCount> {
		return (await APIService.fetchCached(
			'/users/messages/me/count',
			'GET'
		)) as MessagesCount;
	}

	public async getMessages(): Promise<Messages> {
		return (await APIService.fetchCached(
			'/users/messages/me',
			'GET'
		)) as Messages;
	}
}

export const MessagesService = new MessagesServiceController();
