import { APIService } from './base/APIService';
import { CacheService } from './base/CacheService';

export type WorkItem = {
	id: string;
	image_url: string;
	description: string;
	preview: string;
	brand_html: string;

	likes_count: number;
	dislikes_count: number;

	is_deleted: boolean;

	owner_id: string;

	user_avatar: string;
	user_nickname: string;

	created_at: string; // 2020.02.20
};
export type ProfileWorkItem = {
	image_id: string;
	image_url: string;
};
export type Works<TItem = WorkItem> = {
	items: TItem[];

	total: number;
	page: number;
	size: number;
	pages: number;
};

export type HourTopic = {
	result: boolean;
	topic: string;
	brand_site_html: string;
	brand_site: string;
};

export type SavedLikes = {
	[workId: string]: boolean;
};

const FEED_ITERATION_SIZE = 5;
const FEED_SELF_ITERATION_SIZE = 50;
const FEED_USER_ITERATION_SIZE = 50;
const FEED_FOR_LIKES_SIZE = 10;

class WorksServiceController {
	public async getWork(
		id: string
	): Promise<{ result: boolean; work: WorkItem[] }> {
		return await APIService.fetchCached(`/works/${id}`, 'GET', false);
	}

	public async getFeed(iteration = 1): Promise<Works> {
		return (await APIService.fetch(
			`/works/newsfeed?page=${iteration}&size=${FEED_ITERATION_SIZE}`,
			'GET'
		)) as Works;
	}

	public async getTopic(): Promise<HourTopic> {
		return (await APIService.fetch('/hour_topic', 'GET')) as HourTopic;
	}

	public get likedWorks(): SavedLikes {
		// In-memory
		if (CacheService.has('liked_works')) {
			return CacheService.get<SavedLikes>('liked_works') as SavedLikes;
		}
		// LocalStorage
		else {
			const rawStored = localStorage.getItem('liked_works');
			if (!rawStored) {
				return {};
			} else {
				const stored = JSON.parse(rawStored) as SavedLikes;
				CacheService.set('liked_works', stored);
				return stored;
			}
		}
	}
	public set likedWorks(val: SavedLikes) {
		// In-memory
		CacheService.set<SavedLikes>('liked_works', val);
		// LocalStorage
		localStorage.setItem('liked_works', JSON.stringify(val));
	}

	public async likeWork(workId: string) {
		await APIService.fetch(`/works/like/${workId}`, 'POST');
	}

	public async getWorksForLikes(): Promise<Works> {
		const works: Works = await APIService.fetch(
			`/works/newsfeed?page=1&size=${FEED_FOR_LIKES_SIZE}`,
			'GET'
		);

		return works;
	}

	public async getSelfWorks(iteration = 1): Promise<Works<WorkItem>> {
		const works: Works<WorkItem> = await APIService.fetchCached(
			`/works/me?page=${iteration}&size=${FEED_SELF_ITERATION_SIZE}`,
			'GET'
		);

		return works;
	}

	public async getUserWorks(
		user_id: string,
		iteration = 1
	): Promise<Works<WorkItem>> {
		const works: Works<WorkItem> = await APIService.fetchCached(
			`/user_work/${user_id}?page=${iteration}&size=${FEED_USER_ITERATION_SIZE}`,
			'GET'
		);

		return works;
	}

	public async publishWork(image_url: string, description: string) {
		await APIService.fetch(`/works/`, 'POST', true, {
			image_url,
			description
		});
	}
}

export const WorksSevice = new WorksServiceController();
