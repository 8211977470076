import { APIService } from './base/APIService';

export type Achievement = {
	id: string;
	image_url: string;
	name: string;
};

export type AchievementsResult = {
	achievements?: Achievement[];
	result: boolean;
};

class AchievementsController {
	public async getAchievements(): Promise<AchievementsResult> {
		return await APIService.fetchCached(`/achievements`, 'GET');
	}

	public async getSelfAchievements(): Promise<AchievementsResult> {
		return await APIService.fetchCached(`/achievements/me`, 'GET');
	}
	public async getUserAchievements(id: string): Promise<AchievementsResult> {
		return await APIService.fetchCached(`/achievements/${id}`, 'GET');
	}
}

export const AchievementsService = new AchievementsController();
