import { APIService } from './base/APIService';

interface CreateTaskResult {
	task_url: string;
}
interface CheckTaskResult {
	error: null | string;
	queue: string;
	task: null | string[];
	task_url?: string;
}

class GenerationServiceController {
	public async generate(
		prompt: string,
		onFinish: (images: string[] | null) => void
	) {
		const task_url = await this.createGenerateTask(prompt);

		const CHECK_INTERVAL = 1000 * 10; // 10s
		const checkTaskInterval = setInterval(async () => {
			const checkResult = await this.checkGenerateTask(task_url);

			if (checkResult === false) {
				clearInterval(checkTaskInterval);
				onFinish(null);
			}
			// Got result
			else if (checkResult !== null) {
				clearInterval(checkTaskInterval);
				onFinish(checkResult);
			}
		}, CHECK_INTERVAL);
	}

	private async createGenerateTask(prompt: string): Promise<string> {
		const result: CreateTaskResult = await APIService.fetch(
			'/text2image/',
			'POST',
			true,
			{
				prompt,
				negative_prompt: '',
				ai_model: '',
				image_file_name: ''
			}
		);

		return result.task_url;
	}

	// Checks status of generation task and returns null (if not finished) or array of images urls (if finished)
	private async checkGenerateTask(
		task_url: string
	): Promise<null | string[] | false> {
		const result: CheckTaskResult = await APIService.fetchGet(task_url);

		if (result.task_url === 'Error: Not enough funds on balance') {
			return false;
		}

		if (result.task === null) return null;
		else {
			return result.task;
		}
	}
}

export const GenerationService = new GenerationServiceController();
