import { APIService } from './base/APIService';

export type Profile = {
	id: string;
	nickname: string;
	email: string | null;
	is_active: boolean;
	avatar: string;
};
export type ProfileEditOptions = Pick<Profile, 'email' | 'nickname'>;
export type ProfileImage = {
	url: string;
};

export type Balance = {
	balance: number;
	user_id: string;
	wallet: string;
	wrub_balance: number;
};

export type Referral = {
	user_id: string;
	words: string;
};

export type UseReferral = {
	result: 'True' | 'False';
};

class ProfileServiceController {
	public async getProfile(): Promise<Profile> {
		return await APIService.fetchCached('/users/me', 'GET');
	}
	public async getUserProfile(user_id: string): Promise<Profile> {
		return await APIService.fetchCached('/users/' + user_id, 'GET');
	}

	public async getBalance(): Promise<Balance> {
		return await APIService.fetchCached('/users/balance/me', 'GET');
	}

	public async getProfileImages(): Promise<ProfileImage[]> {
		const images: ProfileImage[] = await APIService.fetchCached(
			'/images/me',
			'GET'
		);
		return images;
	}

	public async changeProfile(opts: ProfileEditOptions): Promise<void> {
		await APIService.fetch(`/users/`, 'POST', true, {
			...opts,
			description: ''
		});
	}
	public async changeProfileAvatar(avatar: File): Promise<void> {
		// Upload file as form data
		const formData = new FormData();
		formData.append('file', avatar);

		// Send file
		await APIService.fetch('/users/avatar/', 'POST', true, formData);
	}

	public async getReferral() {
		return (await APIService.fetchCached(
			`/friends/me`,
			'GET',
			true
		)) as Referral;
	}

	public async useReferral(code: string) {
		return (await APIService.fetch('/friends/user_friend', 'POST', true, {
			code
		})) as UseReferral;
	}
}

export const ProfileService = new ProfileServiceController();
