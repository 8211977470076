import { APIService } from './base/APIService';
import { CacheService } from './base/CacheService';

export type AuthResult = {
	result: boolean;
	created: boolean;
	user?: unknown; //TODO: User model
	access_token?: string;
	token_type?: 'bearer';
};

type StoredValue = string | undefined;

class AuthServiceController {
	private get deviceId(): StoredValue {
		return localStorage.getItem('device_id') ?? undefined;
	}
	private set deviceId(val: StoredValue) {
		if (val !== undefined) localStorage.setItem('device_id', val);
		else localStorage.removeItem('device_id');
	}

	public get accessToken(): StoredValue {
		return localStorage.getItem('access_token') ?? undefined;
	}
	private set accessToken(val: StoredValue) {
		if (val !== undefined) localStorage.setItem('access_token', val);
		else localStorage.removeItem('access_token');
	}

	public get authorized(): boolean {
		return typeof this.accessToken === 'string';
	}

	public async authorizeAnonymous(): Promise<void> {
		const { access_token, device_id } = await APIService.fetch(
			'/tokens/',
			'POST',
			false
		);

		// Save
		this.accessToken = access_token;
		this.deviceId = device_id;
	}

	public async authorize(
		email: string,
		password: string
	): Promise<AuthResult> {
		const resp = (await APIService.fetch('/users/auth', 'POST', false, {
			email,
			password
		})) as AuthResult;

		// Success -> set access_token
		if (resp.result === true && 'access_token' in resp) {
			this.accessToken = resp.access_token;
			console.log(
				'Successfully logged in',
				this.accessToken,
				this.authorized
			);
		}

		return resp;
	}

	public unauthorize(): void {
		// Clear likes
		localStorage.removeItem('liked_works');

		// Clear cache
		CacheService.reset();

		// Log out
		this.accessToken = undefined;
		this.deviceId = undefined;
	}
}

export const AuthService = new AuthServiceController();
