import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Toastify
import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import './assets/scss/reset.scss';
import './assets/scss/fonts.scss';
import './assets/scss/vars.scss';
import './assets/scss/button.scss';
import './assets/scss/input.scss';
import './assets/scss/toast.scss';
import './assets/scss/tooltip.scss';

import './registerServiceWorker';

createApp(App)
	.use(router)
	.use(Vue3Toastify, {
		autoClose: 3000,
		position: toast.POSITION.BOTTOM_RIGHT
	})
	.mount('#app');
