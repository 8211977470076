export type OnboardState = {
	finished: boolean;
};
export type StepableOnboardState = OnboardState & {
	step: number;
};

type StoredValue<T> = T | undefined;
class OnboardServiceController {
	public get welcomeState(): StoredValue<OnboardState> {
		const rawState = localStorage.getItem('onboard_state_welcome');
		return rawState ? JSON.parse(rawState) : undefined;
	}
	public set welcomeState(val: StoredValue<OnboardState>) {
		if (val)
			localStorage.setItem('onboard_state_welcome', JSON.stringify(val));
	}

	public get onboardTabsState(): StoredValue<OnboardState> {
		const rawState = localStorage.getItem('onboard_state_tabs');
		return rawState ? JSON.parse(rawState) : undefined;
	}
	public set onboardTabsState(val: StoredValue<OnboardState>) {
		if (val)
			localStorage.setItem('onboard_state_tabs', JSON.stringify(val));
	}

	public get onboardTourState(): StoredValue<StepableOnboardState> {
		const rawState = localStorage.getItem('onboard_state_tour');
		return rawState ? JSON.parse(rawState) : undefined;
	}
	public set onboardTourState(val: StoredValue<StepableOnboardState>) {
		if (val)
			localStorage.setItem('onboard_state_tour', JSON.stringify(val));
	}
}

export const OnboardSevice = new OnboardServiceController();
