import { toast } from 'vue3-toastify';
import { AuthService } from '../AuthService';
import { CacheService } from './CacheService';

type APIMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE';
type APIBody = Record<string, unknown> | FormData;

class APIServiceController {
	public async fetchGet(url: string, auth = true) {
		const headers: Record<string, string | undefined> = {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8'
		};

		if (auth) {
			if (!AuthService.accessToken) {
				console.log('Requesting authorization for ', url);
				toast.error('Не авторизован');
				//TODO: some error or redirect
				return;
			}

			headers['jwt-token'] = AuthService.accessToken as string;
		}

		const response = await fetch(url, {
			method: 'GET',
			headers: headers as HeadersInit
		});

		return response.json();
	}

	public async fetch(
		url: string,
		method: APIMethod,
		auth = true,
		body?: APIBody
	) {
		const headers: Record<string, string | undefined> = {
			Accept: 'application/json'
		};
		if (body instanceof FormData === false) {
			headers['Content-Type'] = 'application/json;charset=UTF-8';
		}
		if (auth) {
			if (!AuthService.accessToken) {
				console.log('Requesting authorization for ', method, url);
				toast.error('Не авторизован');
				//TODO: some error or redirect
				return;
			}

			headers['jwt-token'] = AuthService.accessToken as string;
		}

		if (method !== 'GET' && !body) {
			body = {};
		}

		// Serialize (if its JSON)
		let bodySerialized: string | undefined | FormData = undefined;
		if (body instanceof FormData) bodySerialized = body;
		else if (body) bodySerialized = JSON.stringify(body);

		console.log('Fetching...', method, url, bodySerialized ?? '');

		const response = await fetch('https://api.burngram.com' + url, {
			method,
			body: bodySerialized,
			headers: headers as HeadersInit,
			mode: 'cors'
		});

		return await response.json();
	}

	public async fetchCached(url: string, method: APIMethod, auth = true) {
		const cached = CacheService.get<APIBody>(url);
		if (cached) return cached;
		else {
			const result = await this.fetch(url, method, auth);
			CacheService.set<APIBody>(url, result);
			return result;
		}
	}
}

export const APIService = new APIServiceController();
