import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import { AuthService } from '@/services/AuthService';
import { OnboardSevice } from '@/services/OnboardService';

import WelcomeView from '@/views/WelcomeView.vue';
import TourView from '@/views/TourView.vue';
import NotificationsView from '@/views/NotificationsView.vue';
import FeedView from '@/views/FeedView.vue';
import WorkView from '@/views/WorkView.vue';
import ProfileView from '@/views/ProfileView.vue';
import ProfileEditView from '@/views/ProfileEditView.vue';
import ProfileUserView from '@/views/ProfileUserView.vue';
import MyWorksView from '@/views/MyWorksView.vue';
import BalanceView from '@/views/BalanceView.vue';
import PaymentStatusView from '@/views/PaymentStatusView.vue';
import CreateView from '@/views/CreateView.vue';
import TopView from '@/views/TopView.vue';
import AuthView from '@/views/AuthView.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/welcome',
		name: 'WelcomeView',
		component: WelcomeView
	},
	{
		path: '/tour',
		name: 'TourView',
		component: TourView,
		meta: { isMain: true }
	},
	{
		path: '/auth',
		name: 'AuthView',
		component: AuthView
	},

	{
		path: '/',
		name: 'FeedView',
		component: FeedView,
		meta: { isMain: true }
	},

	{
		path: '/work',
		name: 'WorkView',
		component: WorkView,
		meta: { isMain: true }
	},
	{
		path: '/notifications',
		name: 'NotificationsView',
		component: NotificationsView,
		meta: { isMain: true }
	},
	{
		path: '/profile',
		name: 'ProfileView',
		component: ProfileView,
		meta: { isMain: true }
	},
	{
		path: '/profile/edit',
		name: 'ProfileEditView',
		component: ProfileEditView,
		meta: { isMain: true }
	},
	{
		path: '/profile/works',
		name: 'MyWorksView',
		component: MyWorksView,
		meta: { isMain: true }
	},
	{
		path: '/profile/user',
		name: 'ProfileUserView',
		component: ProfileUserView,
		meta: { isMain: true }
	},
	{
		path: '/balance',
		name: 'BalanceView',
		component: BalanceView,
		meta: { isMain: true, isLight: true }
	},
	{
		path: '/payment-status',
		name: 'PaymentStatusView',
		component: PaymentStatusView,
		meta: { isMain: true, isLight: false }
	},
	{
		path: '/create',
		name: 'CreateView',
		component: CreateView,
		meta: { isMain: true }
	},
	{
		path: '/top',
		name: 'TopView',
		component: TopView,
		meta: { isMain: true }
	}
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
});
router.beforeEach((to) => {
	const { authorized } = AuthService;
	const { welcomeState } = OnboardSevice;
	const { onboardTourState } = OnboardSevice;

	if (to.name === 'WorkView' || to.name === 'WelcomeView') {
		return true;
	}
	// Not finished welcome
	else if (
		(welcomeState === undefined || welcomeState.finished === false) &&
		to.name !== 'WelcomeView'
	) {
		return { name: 'WelcomeView' };
	}
	// Unauthorized
	else if (authorized === false && to.name === 'AuthView') {
		return true;
	} else if (authorized === false && to.name !== 'AuthView') {
		return { name: 'AuthView' };
	}
	// Not finished tour
	else if (
		authorized &&
		(onboardTourState === undefined || onboardTourState.step === 0) &&
		to.name !== 'TourView'
	) {
		return { name: 'TourView' };
	}
	// Authorized
	else if (authorized === true && to.name === 'AuthView') {
		return { name: 'FeedView' };
	}
	// Other scenarios
	else {
		// Forbidden during onboard tour
		if (onboardTourState?.finished === false) {
			return true;
		}

		return true;
	}
});

export default router;
