import { OnboardSevice } from './OnboardService';

export type StepListener = (step: number, finished: boolean) => void;

class TourServiceController {
	public changeStep(step: number, finished = false): void {
		OnboardSevice.onboardTourState = {
			step,
			finished
		};

		this.stepListeners.forEach((listener) => listener(step, finished));
	}

	private stepListeners: StepListener[] = [];
	public addStepListener(listener: StepListener): void {
		this.stepListeners.push(listener);
	}
}

export const TourService = new TourServiceController();
